<template>
  <div class="wrap">
    <van-row class="row">
      <van-col span="12" class="col"
        ><span class="label">历史检测总量：</span> {{ totalInspectionCount || '-' }} 次</van-col
      >
      <van-col span="12" class="col"
        ><span class="label">今日检测总量：</span> {{ TodayInspection || '-' }} 次</van-col
      >
    </van-row>
    <van-row class="row">
      <!-- <van-col span="12" class="col"
        ><span class="label">历史检测合格率：</span>%</van-col
      > -->
      <van-col span="12" class="col"
        ><span class="label">今日检测合格率：</span>{{ TodayInspectionPass || '-' }}%</van-col
      >
    </van-row>
  </div>
</template>

<script>
import { reactive, ref } from 'vue'
import { getList, getTotalInspection, getAnalysis } from '@/api/home'
export default {
  setup() {
    let TotalInspection = ref(''), TodayInspection = ref(''), TodayInspectionPass = ref('')
    getTotalInspection().then(res => {
      TotalInspection.value = res.result.totalInspectionCount
    })
    // 今日检测
    getAnalysis().then((res) => {
      let checkCount = 0, positiveCount = 0, result = res.result.pop()

      checkCount = result.checkCount
      positiveCount = result.positiveCount
      TodayInspection.value = checkCount
      TodayInspectionPass.value = (checkCount - positiveCount) / checkCount * 100
    })
    return {
      TotalInspection,
      TodayInspection,
      TodayInspectionPass
    }
  }
}
</script>

<style lang="less" scoped>
.wrap {
  padding: 12px 0;
  background: #fff;
}
.row {
  .col {
    display: flex;
    align-items: center;
    color: #666;
    font-size: 15px;
    line-height: 32px;
    padding-left: 16px;
  }
  .label {
    color: #333;
  }
}
</style>
