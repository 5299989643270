// 菜单切换
export const menuList = [
  {
    label: '检测信息',
    link: '/home',
    src: require('@/assets/menu/1.png'),
  },
  {
    label: '溯源演示',
    link: '/traceability',
    src: require('@/assets/menu/2.png'),
  },
  {
    label: '市场监管',
    link: '/supervision',
    src: require('@/assets/menu/3.png'),
  },
  {
    label: '食安资讯',
    link: '/news?category=8',
    src: require('@/assets/menu/4.png'),
  },
  {
    label: '食安指数',
    link: '/exponent',
    src: require('@/assets/menu/5.png'),
  },
]

// 首页tab切换
export const homeTabList = [
  {
    label: '今日检测信息',
    link: '/home',
  },
  {
    label: '历史监测信息',
    link: '/home/historical-monitoring',
  },
  {
    label: '数据检测分析',
    link: '/home/analysis',
  },
]

// 溯源演示tab切换
export const traceabilityTabList = [
  {
    label: '经营户档案',
    link: '/traceability',
  },
  {
    label: '冷链溯源',
    link: '/traceability/cold-chain',
  },
  {
    label: '溯源信息公示',
    link: '/traceability/publicity',
  },
]

//  市场监督tab切换
export const supervisionTabList = [
  {
    label: '市场介绍',
    link: '/supervision',
    type: 4
  },
  {
    label: '监管信息',
    link: '/supervision/information',
    type: 3
  },
  {
    label: '视频监控',
    link: '/supervision/video',
  },
]

// 食安资讯tab切换
export const newsTabList = [
  {
    label: '食安法规',
    link: '/news?category=8',
  },
  {
    label: '食安知识',
    link: '/news?category=9',
  },
  {
    label: '食安创建',
    link: '/news?category=10',
  },
]

// 食安指数tab切换
export const exponentTabList = [
  {
    label: '食安健康指数',
    link: '/exponent',
  },
  {
    label: '食安分析地图',
    link: '/exponent/map',
  },
]
