/*
 * @Author: 汪俊洪
 * @Date: 2021-08-31 19:39:56
 * @LastEditors: 汪俊洪
 * @LastEditTime: 2021-08-31 19:43:11
 * @Description: 溯源演示
 */
import Layout from '@/components/layout/index.vue'

export default {
  path: '/traceability',
  component: Layout,
  children: [
    {
      path: '', // 溯源演示
      name: 'traceability',
      component: () =>
        import(
          /* webpackChunkName: "traceability" */ '@/views/traceability/index.vue'
        ),
    },
    {
      path: 'detail', // 溯源演示
      name: 'traceability-detail',
      component: () =>
        import(
          /* webpackChunkName: "traceabilityDetail" */ '@/views/traceability/detail.vue'
        ),
    },
    {
      path: 'cold-chain', // 冷链溯源
      name: 'cold-chain',
      component: () =>
        import(
          /* webpackChunkName: "cold-chain" */ '@/views/traceability/cold-chain.vue'
        ),
    },
    {
      path: 'publicity', // 溯源信息公示
      name: 'publicity',
      component: () =>
        import(
          /* webpackChunkName: "publicity" */ '@/views/traceability/publicity.vue'
        ),
    },
  ],
}
