<template>
  <div class="tab" :class="{ showLine: showLine }">
    <div
      v-for="(item, index) in list"
      :key="index"
      @click="() => routeChange(item)"
      :class="{ active: index === current }"
    >
      {{ item.label }}
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { getCurrentInstance } from 'vue'
export default {
  props: {
    list: {
      type: Array,
      default: function () {
        return []
      },
    },
    showLine: {
      type: Boolean,
      default: false,
    },
    current: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const router = useRouter()
    const { ctx } = getCurrentInstance()
    const routeChange = (item) => {
      router.push(item.link)
      // console.log(ctx.$router.currentRoute.value.fullPath)
      // if (this.name)
      // router.push({ path: item.link, query: { type: item.type } })
    }
    return {
      routeChange,
    }
  },
}
</script>

<style lang="less" scoped>
.tab {
  display: flex;
  justify-content: space-between;
  padding: 20px 16px 0;
  color: #fff;
  font-size: 15px;
  &.showLine {
    border-bottom: 1px solid #fff;
  }
  > div {
    position: relative;
    flex: 1;
    padding-bottom: 11px;
    text-align: center;
    &::after {
      position: absolute;
      left: calc(50% - 20px);
      bottom: 0;
      display: block;
      width: 40px;
      height: 3px;
      background: transparent;
      border-radius: 2px;
      content: ' ';
    }
    &.active::after {
      background: #fff;
    }
  }
}
</style>
