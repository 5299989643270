// import $http from '@/utils/http'
import request from '@/utils/request'

export function getList() {
  return request({
    url: '/publicity/home',
    method: 'get'
  })
}

export function getListByDate(date) {
  return request({
    url: `/publicity/home/${date}`,
    method: 'get'
  })
}

export function getAnalysis() {
  return request({
    url: `/publicity/graph/lastTwoWeek`,
    method: 'get'
  })
}

export function getTotalInspection() {
  return request({
    url: `/publicity/getTotalInspection`,
    method: 'get'
  })
}

// export function getAnalysis() {
//   return new Promise((resolve) => {
//     const list = [
//       {
//         label: 1,
//         value: 100,
//       },
//       {
//         label: 2,
//         value: 100,
//       },
//       {
//         label: 3,
//         value: 100,
//       },
//       {
//         label: 4,
//         value: 100,
//       },
//       {
//         label: 5,
//         value: 100,
//       },
//       {
//         label: 6,
//         value: 100,
//       },
//       {
//         label: 7,
//         value: 100,
//       },
//       {
//         label: 8,
//         value: 100,
//       },
//       {
//         label: 9,
//         value: 100,
//       },
//       {
//         label: 10,
//         value: 100,
//       },
//       {
//         label: 11,
//         value: 98,
//       },
//       {
//         label: 12,
//         value: 100,
//       },
//       {
//         label: 13,
//         value: 95,
//       },
//       {
//         label: 14,
//         value: 100,
//       },
//       {
//         label: 15,
//         value: 100,
//       },
//     ]
//     setTimeout(() => {
//       resolve(list)
//     }, 300)
//   })
// }
