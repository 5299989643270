/**
 *@desc axios封装请求
 *@author law
 *@date 2021/11/11
 */

import axios from 'axios'
import { Toast } from 'vant';
import { storage } from './storage'
import store from '@/store'

// 创建axios实例
const request = axios.create({
  // baseURL: process.env.BASE_API, // api的base_url
  baseURL: process.env.VUE_APP_BASEURL,
  timeout: 60000 // 请求超时时间
})

//添加请求拦截器
request.interceptors.request.use(
  (config) => {
    if (store.state.token) {
      config.headers.access_token = store.state.token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

//添加响应拦截器
request.interceptors.response.use(
  (response) => {
    // console.log(response)
    const res = response.data
    if (res.resultCode !== 0) {
      if (res.resultCode == 401) {
        Toast({
          message: '登录失效'
        })
      }
      // console.log(res)
    } else {
      return res
    }
  },
  () => {
    return {
      data: {
        code: 666,
        cancel: true,
        errorMsg: '请求已关闭',
      }
    }
  }
)

export default request
