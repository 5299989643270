<!--
 * @Author: 汪俊洪
 * @Date: 2021-08-31 19:34:47
 * @LastEditors: 汪俊洪
 * @LastEditTime: 2021-08-31 20:48:10
 * @Description: 
-->
<template>
<div @click="goBack" class="flex-col flex flex-1">
    <!-- 轮播 -->
    <Swiper />
    <!-- 统计 -->
    <Statistics />
    <div class="flex-col flex-1 fff">
      <Table class="flex-col fff" :tableConfig="tableConfig">
        <template v-slot:param3="scope">
          {{ scope.row.param3 }}&nbsp;{{ scope.row.param4 }}
        </template>
        <template v-slot:param7="scope">
          {{ scope.row.param6 }}&nbsp;<span :class="`color-${scope.row.param7}`">{{
            scope.row.param7 === 1 ? '合格' : '不合格'
          }}</span>
        </template>
      </Table>
    </div>
</div>
</template>

<script>
import Table from '@/components/table/index.vue'
import { reactive } from 'vue'
import { getList } from '@/api/home'
import Swiper from './rest/swiper.vue'
import Statistics from './rest/statistics.vue'
import { useRouter } from 'vue-router'

export default {
  components: {
    Table,
    Swiper,
    Statistics,
  },
  setup() {
    // 表格相关的
    const tableConfig = reactive({
      params: {
        param1: {
          label: '摊位号',
          align: 'center',
          width: '15%',
        },
        param2: {
          label: '经营户',
          align: 'center',
          width: '15%',
        },
        param3: {
          label: '样品编号/名称',
          align: 'center',
          width: '30%',
          valueType: 'slot',
        },
        param5: {
          label: '检测项目',
          align: 'center',
          width: '15%',
        },
        param7: {
          label: '检测结果',
          align: 'center',
          width: '25%',
          valueType: 'slot',
        },
      },
      data: [],
    })
    getList().then((res) => {
      tableConfig.data = res.result
    })
    const router = useRouter()
    const goBack = () => {
      router.go(-1)
      console.log(1)
    }
    return {
      tableConfig,
      goBack
    }
  },
}
</script>

<style lang="less" scoped>
.color-1 {
  color: #49c5a5;
}
.color-0 {
  color: #f00;
}
</style>
