<template>
  <div class="table flex-col">
    <div class="item table-head">
      <div
        :style="{ flex: `0 0 ${tableConfig.params[key].width}` }"
        v-for="(key, index) in Object.keys(tableConfig.params)"
        :key="index"
      >
        {{ tableConfig.params[key].label }}
      </div>
    </div>
    <js-seamless-scroll
      v-if="needScroll"
      :datas="tableConfig.data"
      class="table-body fff flex-1"
    >
      <div class="item" v-for="(item, index) in tableConfig.data" :key="index">
        <template
          v-for="(key, index) in Object.keys(tableConfig.params)"
          :key="index"
        >
          <div :style="{ flex: `0 0 ${tableConfig.params[key].width}` }">
            <slot
              v-if="tableConfig.params[key].valueType === 'slot'"
              :name="key"
              :row="item"
            ></slot>
            <template v-else>{{ item[key] }}</template>
          </div>
        </template>
      </div>
      <div class="noData" v-if="tableConfig.data.length <= 0">暂无数据</div>
    </js-seamless-scroll>
    <div v-else :datas="tableConfig.data" class="table-body fff flex-1">
      <div class="item" v-for="(item, index) in tableConfig.data" :key="index">
        <template
          v-for="(key, index) in Object.keys(tableConfig.params)"
          :key="index"
        >
          <div :style="{ flex: `0 0 ${tableConfig.params[key].width}` }">
            <slot
              v-if="tableConfig.params[key].valueType === 'slot'"
              :name="key"
              :row="item"
            ></slot>
            <template v-else>{{ item[key] }}</template>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
// import { jsSeamlessScroll } from 'vue3-seamless-scroll'
export default {
  props: {
    tableConfig: {
      type: Object,
      default: function () {
        return {}
      },
    },
    needScroll: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    // jsSeamlessScroll,
  },
}
</script>

<style lang="less" scoped>
.table {
  .table-body {
    flex: 1;
    overflow: hidden;
    overflow: auto!important;
    .item:nth-child(2n) {
      background: #f6f9fa;
    }
  }

  .item {
    display: flex;
    align-items: center;
    min-height: 44px;
    color: #3c414d;
    font-size: 14px;
    text-align: center;
    &.table-head {
      height: 44px;
      background: #1b51f1;
      color: #fff;
    }
    > div {
      flex: 1;
    }
  }
}
</style>
