/*
 * @Author: 汪俊洪
 * @Date: 2021-08-31 19:32:20
 * @LastEditors: 汪俊洪
 * @LastEditTime: 2021-08-31 19:33:56
 * @Description: 主入口
 */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './styles/common.less'
import './utils/rem.js'
import Vant from 'vant'
import 'vant/lib/index.css'

createApp(App).use(store).use(router).use(Vant).mount('#app')
// const app = createApp(App)
// app.config.globalProperties.$mybus = mitt()
