/*
 * @Author: 汪俊洪
 * @Date: 2021-08-31 19:39:56
 * @LastEditors: 汪俊洪
 * @LastEditTime: 2021-08-31 19:43:11
 * @Description: 市场监管
 */
import Layout from '@/components/layout/index.vue'

export default {
  path: '/supervision',
  component: Layout,
  children: [
    {
      path: '', // 市场介绍
      name: 'supervision',
      component: () =>
        import(
          /* webpackChunkName: "supervision" */ '@/views/supervision/index.vue'
        ),
    },
    {
      path: 'information', // 监管信息
      name: 'information',
      component: () =>
        import(
          /* webpackChunkName: "information" */ '@/views/supervision/information.vue'
        ),
    },
    {
      path: 'video', // 视频监控
      name: 'video',
      component: () =>
        import(/* webpackChunkName: "video" */ '@/views/supervision/video.vue'),
    },
  ],
}
