/*
 * @Author: 汪俊洪
 * @Date: 2021-06-23 18:56:57
 * @LastEditors: 汪俊洪
 * @LastEditTime: 2021-08-31 20:35:23
 * @Description: 日期格式化 yyyy-MM-dd hh:mm:ss
 */

//兼容ie
function setDate(date) {
  let d = date.replace(/-/g, '/')
  d = d.replace('T', ' ')
  d = d.substr(0, 19)
  return d
}

export function formateDate(fmt = 'yyyy-MM-dd hh:mm:ss', time) {
  if (time == null || time == '') {
    return
  }
  //如果是太平洋时间格式
  if (typeof time === 'string') {
    if (time.indexOf('T') !== -1) {
      time = setDate(time)
      time = new Date(time).getTime() + 8 * 3600 * 1000
    } else {
      time = time.replace(/-/g, '/')
    }
  }

  let date = new Date(time)
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length)
    )
  }
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
  }
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + ''
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? str : padLeftZero(str)
      )
    }
  }
  return fmt
}

function padLeftZero(str) {
  return ('00' + str).substr(str.length)
}

export default formateDate
