<template>
  <div class="statistics">
    <div
      v-for="(item, index) in list"
      :key="index"
      :class="{ active: index === 0 }"
    >
      <span class="label">{{ item.label }}</span>
      <span class="value">{{ item.value }}</span>
      <span class="unit">{{ item.unit }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  setup() {
    return {}
  },
}
</script>

<style lang="less" scoped>
.statistics {
  display: flex;
  align-items: center;
  margin: 16px;
  height: 40px;
  font-size: 14px;
  background: #ffffff;
  border-radius: 22px;
  > div {
    flex: 1;
    padding-left: 20px;
    color: #3c414d;
    .label {
      color: #3c6dff;
    }
    .value {
      font-size: 22px;
      padding: 0 2px 0 6px;
    }
  }
}
</style>
