<template>
  <div class="page flex-col">
    <Title />
    <Menu />
    <div class="flex-1 flex-col"><router-view></router-view></div>
    <Bottom />
  </div>
</template>
<script>
import Title from './title.vue'
import Menu from './menu.vue'
import Bottom from '../bottom/index.vue'
import store from '@/store/index'

export default {
  components: {
    Title,
    Menu,
    Bottom,
  },
  setup() {
    console.log('layoutindex')
    if (location.href.indexOf('?') !== -1) {
      localStorage.setItem('account', location.href.split('?')[1].split('=')[1]);
      setInterval(() => {
        store
          .dispatch('Login', localStorage.getItem('account'))
          .then(() => {
            console.log('刷新成功')
          })
      }, 600000)
    } else if(localStorage.getItem('account')){
      setInterval(() => {
        store
          .dispatch('Login', localStorage.getItem('account'))
          .then(() => {
            console.log('刷新成功')
          })
      }, 600000)
    }
  },
}
</script>
<style lang="less" scoped>
.page {
  background: linear-gradient(
    97deg,
    #3a6dff 0%,
    #4c6cff 67%,
    #4598fa 80%,
    #4076fd 100%
  );
}
</style>
