/*
 * @Author: 汪俊洪
 * @Date: 2021-08-31 19:32:20
 * @LastEditors: 汪俊洪
 * @LastEditTime: 2021-08-31 19:52:44
 * @Description:
 */
import { createRouter, createWebHashHistory, RouteRecordRaw, useRouter } from 'vue-router'
import Layout from '@/components/layout/index.vue'
import RestLayout from '@/components/layout/rest.vue'
import Home from '../views/index/index.vue'
import Rest from '../views/index/rest.vue'
import NotFound from '../views/notFound/index.vue'
import home from './home'
import traceability from './traceability'
import supervision from './supervision'
import news from './news'
import exponent from './exponent'
import store from '../store'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/', // 不识别的path自动匹配404
    redirect: '/home',
  },
  {
    path: '/home',
    component: Layout,
    children: [
      {
        path: '', // 历史监测信息
        name: 'home',
        component: Home,
      },
    ],
  },
  {
    path: '/rest',
    name: 'rest',
    component: RestLayout,
    children: [
      {
        path: '', // 历史监测信息
        name: 'rest',
        component: Rest,
      },
    ],
  },
  home,
  traceability,
  supervision,
  news,
  exponent,
  {
    path: '/:catchAll(.*)', // 不识别的path自动匹配404
    redirect: '/404',
  },
  {
    path: '/404',
    component: NotFound,
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

// setInterval
// router.beforeEach((to, from, next) => {
//   let nowTime = new Date().getTime() / 1000
//   store.commit('setLastOperTime', nowTime)
//   // const route = useRouter()
//   const differ = 60 * 0.1 //间隔时间
//   const timer = setInterval(() => {
//     nowTime = new Date().getTime() / 1000
//     if (store.state.lastOperTime) {
//       if (nowTime - store.state.lastOperTime > differ) {
//         console.log(from)
//         console.log(to)
//         store.commit('setLastOperTime', nowTime)
//         if (from.path !== '/rest') {
//           clearInterval(timer)
//           location.href = '#/rest'
//           next()
//           // route.push('/rest')
//         }
//       }
//     }
//   }, 1000)
//   next()
// })
// setTimeout 方法 锁屏
// let timer: number = 0
// const differ = 1000 * 60 * 30 //秒
// router.beforeEach((to, from, next) => {
//   clearTimeout(timer)
//   timer = 0
//   timer = setTimeout(() => {
//     location.href = '#/rest'
//   }, differ)
//   next()
// })

export default router
