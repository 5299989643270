<template>
  <!-- <div class="bottom">技术支持：科技有限公司</div> -->
  <div class="bottom"></div>
</template>

<script></script>

<style lang="less" scoped>
.bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-size: 15px;
  background: #1b51f1;
  color: #fff;
}
</style>
