import { createStore } from 'vuex'
import { storage } from '@/utils/storage'
import { login } from '@/api/login'

export default createStore({
  state: {
    token: storage.get('token'),
    deptName: storage.get('deptName'),
    lastOperTime: storage.get('lastOperTime')
  },
  mutations: {
    // 设置token
    SET_TOKEN: (state: any, token: string): void => {
        storage.set('token', token)
        state.token = token
    },
    setDeptName: (state: any, deptName: string): void => {
      storage.set('deptName', deptName)
      state.deptName = deptName
    },
    setLastOperTime: (state: any, lastOperTime: string): void => {
      storage.set('lastOperTime', lastOperTime)
      state.lastOperTime = lastOperTime
    }
  },
  actions: {
       // 登录
       Login({ commit }, userInfo) {
          commit('SET_TOKEN', '')
          return new Promise((resolve, reject) => {
          login(userInfo).then((response) => {
            console.log(response)
            var res = response.result.token
            storage.set('token', res)
            commit('SET_TOKEN', res)
            resolve(res)
          })
          .catch((error: Object) => {
            reject(error)
          })
          })
      }     
  },
  modules: {},
});
