<template>
  <div class="page flex-col">
    <Title />
    <div class="flex-1 flex-col"><router-view></router-view></div>
    <Bottom />
  </div>
</template>
<script>
import Title from './title.vue'
import Bottom from '../bottom/index.vue'

export default {
  components: {
    Title,
    Bottom,
  },
}
</script>
<style lang="less" scoped>
.page {
  background: linear-gradient(
    97deg,
    #3a6dff 0%,
    #4c6cff 67%,
    #4598fa 80%,
    #4076fd 100%
  );
}
</style>
