<template>
  <div id="certify">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <img
            src="https://www.17sucai.com/preview/414926/2018-06-06/certify/images/certify01.png"
          />
        </div>
        <div class="swiper-slide">
          <img
            src="https://www.17sucai.com/preview/414926/2018-06-06/certify/images/certify02.png"
          />
        </div>
        <div class="swiper-slide">
          <img
            src="https://www.17sucai.com/preview/414926/2018-06-06/certify/images/certify03.png"
          />
        </div>
        <div class="swiper-slide">
          <img
            src="https://www.17sucai.com/preview/414926/2018-06-06/certify/images/certify04.png"
          />
        </div>
        <div class="swiper-slide">
          <img
            src="https://www.17sucai.com/preview/414926/2018-06-06/certify/images/certify05.png"
          />
        </div>
      </div>
    </div>
    <div class="swiper-pagination"></div>
  </div>
</template>

<script>
export default {
  mounted() {
    let certifySwiper = new window.Swiper('#certify .swiper-container', {
      watchSlidesProgress: true,
      slidesPerView: 'auto',
      centeredSlides: true,
      loop: true,
      loopedSlides: 5,
      autoplay: true,
      pagination: {
        el: '.swiper-pagination',
        //clickable :true,
      },
      on: {
        progress: function (progress) {
          for (let i = 0; i < this.slides.length; i++) {
            var slide = this.slides.eq(i)
            var slideProgress = this.slides[i].progress
            let modify = 1
            if (Math.abs(slideProgress) > 1) {
              modify = (Math.abs(slideProgress) - 1) * 0.3 + 1
            }
            let translate = slideProgress * modify * 260 + 'px'
            let scale = 1 - Math.abs(slideProgress) / 5
            let zIndex = 999 - Math.abs(Math.round(10 * slideProgress))
            slide.transform(
              'translateX(' + translate + ') scale(' + scale + ')'
            )
            slide.css('zIndex', zIndex)
            slide.css('opacity', 1)
            if (Math.abs(slideProgress) > 3) {
              slide.css('opacity', 0)
            }
          }
        },
        setTransition: function (transition) {
          for (var i = 0; i < this.slides.length; i++) {
            var slide = this.slides.eq(i)
            slide.transition(transition)
          }
        },
      },
    })
  },
}
</script>

<style lang="less" scoped>
#certify {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

#certify .swiper-container {
  padding: 10px 0 20px;
}

#certify .swiper-slide {
  width: 54%;
}
#certify .swiper-slide img {
  display: block;
  width: 100%;
  border-radius: 4px;
}
/deep/ .swiper-pagination {
  width: 100%;
  bottom: 26px;
}

/deep/ .swiper-pagination-bullet {
  margin: 0 5px;
  background: #a0aecc;
  width: 8px;
  height: 8px;
  opacity: 1;
}

/deep/ .swiper-pagination-bullet-active {
  background-color: #3c6dff;
}
</style>
