/*
 * @Author: 汪俊洪
 * @Date: 2021-08-31 19:39:56
 * @LastEditors: 汪俊洪
 * @LastEditTime: 2021-08-31 19:43:11
 * @Description: 主页路由
 */
import Layout from '@/components/layout/index.vue'

export default {
  path: '/home',
  component: Layout,
  children: [
    {
      path: 'analysis', // 数据检测分析
      name: 'analysis',
      component: () =>
        import(/* webpackChunkName: "analysis" */ '@/views/index/analysis.vue'),
    },
    {
      path: 'historical-monitoring', // 历史监测信息
      name: 'historical-monitoring',
      component: () =>
        import(
          /* webpackChunkName: "historical-monitoring" */ '@/views/index/historical-monitoring.vue'
        ),
    },
  ],
}
