<!--
 * @Author: 汪俊洪
 * @Date: 2021-08-31 19:32:20
 * @LastEditors: 汪俊洪
 * @LastEditTime: 2021-08-31 19:34:17
 * @Description: 
-->
<template>
  <router-view />
</template>

<style lang="less">
.noData {
  text-align: center;
  padding: 10px 0;
}
</style>
