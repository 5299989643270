/*
 * @Author: 汪俊洪
 * @Date: 2021-08-31 19:39:56
 * @LastEditors: 汪俊洪
 * @LastEditTime: 2021-08-31 19:43:11
 * @Description: 食安指数
 */
import Layout from '@/components/layout/index.vue'

export default {
  path: '/exponent',
  component: Layout,
  children: [
    {
      path: '', // 食安健康指数
      name: 'exponent',
      component: () =>
        import(/* webpackChunkName: "exponent" */ '@/views/exponent/index.vue'),
    },
    {
      path: 'map', // 食安分析地图
      name: 'map',
      component: () =>
        import(/* webpackChunkName: "map" */ '@/views/exponent/map.vue'),
    },
  ],
}
