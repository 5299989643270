/*
 * @Author: 汪俊洪
 * @Date: 2021-08-31 19:39:56
 * @LastEditors: 汪俊洪
 * @LastEditTime: 2021-08-31 19:43:11
 * @Description: 食安资讯
 */
import Layout from '@/components/layout/index.vue'

export default {
  path: '/news',
  component: Layout,
  children: [
    {
      path: '', // 食安法规
      name: 'news',
      component: () =>
        import(/* webpackChunkName: "news" */ '@/views/news/index.vue'),
    },
    {
      path: 'detail', // 食安法规
      name: 'newsDetail',
      component: () =>
        import(/* webpackChunkName: "newsDetail" */ '@/views/news/detail.vue'),
    },
  ],
}
