<template>
  <div class="list">
    <div
      class="item"
      :class="{ active: path.indexOf(item.link) === 0 }"
      @click="() => routeChange(item)"
      v-for="(item, index) in list"
      :key="index"
    >
      <img :src="item.src" />
      <div>{{ item.label }}</div>
    </div>
  </div>
</template>

<script>
import { computed, reactive } from 'vue'
import { menuList } from '@/utils/tab'
import { useRoute, useRouter } from 'vue-router'

export default {
  setup() {
    const list = reactive(menuList)

    const router = useRouter()
    const routeChange = (item) => {
      router.push(item.link)
    }

    const route = useRoute()
    const path = computed(() => {
      return route.path
    })

    return {
      list,
      path,
      routeChange,
    }
  },
}
</script>

<style lang="less" scoped>
.list {
  display: flex;
  justify-content: space-between;
  margin: 0 16px;
  padding: 17px 20px;
  background: #fff;
  border-radius: 10px;

  .item {
    width: 56px;
    font-size: 14px;
    color: #656e81;
    text-align: center;
    > div {
      white-space: nowrap;
    }

    img {
      display: block;
      width: 100%;
      height: 56px;
      margin-bottom: 10px;
    }

    &.active {
      color: #0dc9c9;
    }
  }
}
</style>
