<template>
  <div class="title">{{$store.state.deptName || '--市场'}}<span>食品安全信息及溯源信息公示平台</span></div>
  <div class="time">{{ dateStr }}</div>
</template>

<script>
import { onMounted, onBeforeUnmount, computed, ref } from 'vue'
import { formateDate } from '@/utils/formateDate'
import { mapState } from 'vuex'
// import { getDetail } from '@/api/supervision/index'
// import store from '@/store/index'
// import bus from '@/utils/bus' 

export default {
  setup() {
    console.log('title')
    let date = ref(new Date().getTime())
    let interval = ref()
    // const info = ref<{deptName: string}>()
    let deptName = ref('')
    // bus.$on('deptName', (content) => {
    //   deptName.value = content
    // })

    onMounted(() => {
      interval = setInterval(() => {
        date.value += 1000
      }, 1000)
    })

    onBeforeUnmount(() => {
      clearInterval(interval)
    })

    const dateStr = computed(() => {
      return formateDate('yyyy/MM/dd hh:mm:ss', date.value)
    })

    return {
      dateStr,
      deptName
    }
  },
}
</script>

<style lang="less" scoped>
.title {
  padding: 16px 0 12px;
  font-size: 28px;
  color: #fff;
  text-align: center;
  line-height: 39px;
  span {
    display: block;
    font-size: 20px;
    line-height: 28px;
  }
}
.time {
  position: absolute;
  top: 10px;
  right: 16px;
  display: block;
  width: 85px;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  text-align: right;
  font-family: mono;
}
</style>
