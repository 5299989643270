<!--
 * @Author: 汪俊洪
 * @Date: 2021-08-31 19:34:47
 * @LastEditors: 汪俊洪
 * @LastEditTime: 2021-08-31 20:48:10
 * @Description:
-->
<template>
  <Tab :list="tabList" :current="0" :showLine="true" />
  <Statistics :list="statisticsList.arr" />
  <Table class="flex-1" :tableConfig="tableConfig">
    <template v-slot:param3="scope">
      {{ scope.row.param3 }}&nbsp;{{ scope.row.param4 }}
    </template>
    <template v-slot:param7="scope">
      {{ scope.row.param6 }}&nbsp;<span :class="`color-${scope.row.param7}`">{{
        scope.row.param7 === 1 ? '合格' : '不合格'
      }}</span>
    </template>
  </Table>
</template>

<script>
import Tab from '@/components/tab/index.vue'
import Statistics from '@/components/statistics/index.vue'
import Table from '@/components/table/index.vue'
import { reactive, ref } from 'vue'
import { getList, getTotalInspection } from '@/api/home'
import { getDetail } from '@/api/supervision/index'
import { homeTabList } from '@/utils/tab'
import store from '@/store/index'
// import { getCurrentInstance } from 'vue'
// import bus from '@/utils'

export default {
  components: {
    Tab,
    Statistics,
    Table,
  },
  setup() {
    // const { ctx } = getCurrentInstance()
    const tabList = reactive(homeTabList)
    const statisticsList = reactive({
      arr: [
        {
          label: '历史检测总量',
          value: 0,
          unit: '次',
        },
        {
          label: '今日检测总量',
          value: 0,
          unit: '批次',
        },
      ],
    })
    // 表格相关的
    const tableConfig = reactive({
      params: {
        stallName: {
          label: '摊位号',
          align: 'center',
          width: '15%',
        },
        stallUserName: {
          label: '经营户',
          align: 'center',
          width: '15%',
        },
        foodName: {
          label: '样品编号/名称',
          align: 'center',
          width: '25%',
        },
        projectName: {
          label: '检测项目',
          align: 'center',
          width: '20%',
        },
        reportResult: {
          label: '检测结果',
          align: 'center',
          width: '25%',
        },
      },
      data: [],
    })

    if (location.href.indexOf('?') !== -1) {
      store
        .dispatch('Login', location.href.split('?')[1].split('=')[1])
        .then((res) => {
          init()
        })
    } else if (store.state.token) {
      init()
    } else {
      console.log('---')
    }
    function init() {
      getList().then((res) => {
        tableConfig.data = res.result
        statisticsList.arr[1] = {
          label: '今日检测总量',
          value: tableConfig.data.length,
          unit: '批次',
        }
        tableConfig.data.forEach((item, index) => {
          if (!item.stallName) {
            item.stallName = '流动摊位'
          }
          if (!item.stallUserName) {
            item.stallUserName = '-'
          }
        })
      })
      getDetail(4).then((res) => {
        store.commit('setDeptName', res.result[0].deptName)
      })
      getTotalInspection().then((res) => {
        // statisticsList.value.arr[0].value = res.result.totalInspectionCount
        statisticsList.arr[0].value = res.result.totalInspectionCount
      })
    }

    // if (location.href.indexOf('?') !== -1) {
    //   login(location.href.split('?')[1].split('=')[1]).then(res => {
    //     storage.set('token', res.result.token)
    //     getDetail(4).then((res) => {
    //       detail.info = res.result[0]
    //     })
    //   }).catch(res => {
    //     console.log(res)
    //   })
    // } else {
    // }

    return {
      tabList,
      statisticsList,
      tableConfig,
    }
  },
}
</script>

<style lang="less" scoped>
.color-1 {
  color: #49c5a5;
}
.color-0 {
  color: #f00;
}
</style>
